
.button-container {
  display: flex;
  justify-content: space-between; /* Aligns buttons to the left and right */
  padding: 1em 0; /* Adds some vertical padding */
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1em;
    margin-top: 1em;
}
.iframe-container {
  position: relative;
  width: 60%; /* Adjust width to desired size */
  height: 500px; /* Adjust height to desired size */
  margin: 2em auto; /* Centers the container and adds vertical spacing */
  background-color: #fff; /* Keeps the background color white */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ensures the iframe takes the full width of its container */
  height: 100%; /* Ensures the iframe takes the full height of its container */
  border: none; /* Keeps the border removed */
}


/* Remove any unnecessary gray background or padding/margin around the iframe */
body, html {
  margin: 0;
  padding: 0;
  background-color: #fff; /* Set the background color to white or any desired color */
}


