h1.d-inline-block.align-top {
  color: #4A75E6;
}    

.product-image{
  background-color: rgb(241, 241, 241);
}

.search-box {
    width: 100%;
    max-width: 570px;
    margin: 30px auto 50px;
    display: flex;
    align-items: stretch;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
  }
  
  .search-input {
    border: none;
    outline: none;
    background: rgb(241, 241, 241);
    padding: 18px 18px 18px 50px;
    width: calc(100% - 100px);
  }
  
  .search-box ::before {
    content: "";
    position: absolute;
    top: calc(50% - 12px);
    left: 15px;
    width: 20px;
    height: 20px;
    background: url("https://i.ibb.co/M6JLzLk/search.png") no-repeat center center /
      contain;
    z-index: 1;
  }
  
  .search-input::placeholder {
    color: #989898;
  }
  
  .search-btn {
    border: 0;
    color: #fff;
    padding: 15px 20px;
    width: 150px;
    cursor: pointer;
  }
  
  .upload-btn {
    max-width: 170px;
    background-color: #dcffec;
  }
  
  .buy-btn, .checkout-btn {
    font-weight: 500;
    border-radius: 10px;
    border: none;
    background-color: #4A75E6 !important;
    padding: 12px 35px;
  }
  
  .login-btn {
    background-color: #4A75E6 !important;
    border: none;
    padding: 10px 25px;
  }

  p {
    font-family: "Poppins", sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
  }
  
  a,
  a:hover,
  a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }
  
  .navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
  }
  
  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
  }
  
  /* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
  
  .wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    perspective: 1500px;
    background: #f4f7fc;
  }
  
  #sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #4A75E6;
    color: #fff;
    transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
    transform-origin: bottom left;
  }
  
  #sidebar.active {
    margin-left: -250px;
    transform: rotateY(100deg);
  }
  
  #sidebar .sidebar-header {
    padding: 20px;
    background: #4A75E6;
  }
  
  #sidebar ul.components {
    padding: 20px 0;
    /* border-bottom: 1px solid #28404d; */
  }
  
  #sidebar ul p {
    color: #fff;
    padding: 10px;
  }
  
  #sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: white;
    text-decoration: none;
  }
 
  #sidebar ul li a:hover {
    color: #4A75E6;
    background: #fff;
  }
  #sidebar ul li .back-home {
    color: black;
  }
  
  #sidebar ul li.active > a,
  a[aria-expanded="true"] {
    color: #fff;
    background: #4A75E6;
  }
  
  a[data-toggle="collapse"] {
    position: relative;
  }
  
  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  
  ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #4A75E6;
  }
  
  ul.CTAs {
    padding: 20px;
  }
  
  ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  
  a.back-home {
    background: #fff;
    color: black;
  }
  
  a.article,
  a.article:hover {
    background: #4A75E6 !important;
    color: #fff !important;
  }
  
  /* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
  #content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
  }
  
  #sidebarCollapse {
    width: 40px;
    height: 40px;
    background: #f5f5f5;
    cursor: pointer;
  }
  
  #sidebarCollapse span {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    display: block;
    background: #555;
    transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
    transition-delay: 0.2s;
  }
  
  #sidebarCollapse span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
  }
  #sidebarCollapse span:nth-of-type(2) {
    opacity: 0;
  }
  #sidebarCollapse span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
  }
  
  #sidebarCollapse.active span {
    transform: none;
    opacity: 1;
    margin: 5px auto;
  }
  
  /* ---------------------------------------------------
      MEDIAQUERIES
  ----------------------------------------------------- */
  @media (max-width: 768px) {
    #sidebar {
      margin-left: -250px;
      transform: rotateY(90deg);
    }
    #sidebar.active {
      margin-left: 0;
      transform: none;
    }
    #sidebarCollapse span:first-of-type,
    #sidebarCollapse span:nth-of-type(2),
    #sidebarCollapse span:last-of-type {
      transform: none;
      opacity: 1;
      margin: 5px auto;
    }
    #sidebarCollapse.active span {
      margin: 0 auto;
    }
    #sidebarCollapse.active span:first-of-type {
      transform: rotate(45deg) translate(2px, 2px);
    }
    #sidebarCollapse.active span:nth-of-type(2) {
      opacity: 0;
    }
    #sidebarCollapse.active span:last-of-type {
      transform: rotate(-45deg) translate(1px, -1px);
    }
  }
  
  /* Footer */
  
  .section-footer ul li {
    margin-bottom: 5px;
  }
  
  .section-footer ul a {
    color: inherit;
  }
  
  .section-footer ul a:hover {
    color: #4A75E6;
  }
  
  .section-footer .logo-footer {
    height: 40px;
  }
  
  .section-footer .title {
    font-weight: 600;
    margin-bottom: 16px;
    color: #1e2024;
  }
  
  .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .footer-copyright {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  a.footer-btn {
    color: #007bff;
    font-weight: 500;
}

  .btn-grad {background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%)}
.btn-grad {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
 }
.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
  
.search-btn{
  margin: 0;
  border-radius: 0;
}