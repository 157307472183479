@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

h1.d-inline-block.align-top {
  color: #4A75E6;
}    

.product-image{
  background-color: rgb(241, 241, 241);
}

.search-box {
    width: 100%;
    max-width: 570px;
    margin: 30px auto 50px;
    display: flex;
    align-items: stretch;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
  }
  
  .search-input {
    border: none;
    outline: none;
    background: rgb(241, 241, 241);
    padding: 18px 18px 18px 50px;
    width: calc(100% - 100px);
  }
  
  .search-box ::before {
    content: "";
    position: absolute;
    top: calc(50% - 12px);
    left: 15px;
    width: 20px;
    height: 20px;
    background: url("https://i.ibb.co/M6JLzLk/search.png") no-repeat center center /
      contain;
    z-index: 1;
  }
  
  .search-input::placeholder {
    color: #989898;
  }
  
  .search-btn {
    border: 0;
    color: #fff;
    padding: 15px 20px;
    width: 150px;
    cursor: pointer;
  }
  
  .upload-btn {
    max-width: 170px;
    background-color: #dcffec;
  }
  
  .buy-btn, .checkout-btn {
    font-weight: 500;
    border-radius: 10px;
    border: none;
    background-color: #4A75E6 !important;
    padding: 12px 35px;
  }
  
  .login-btn {
    background-color: #4A75E6 !important;
    border: none;
    padding: 10px 25px;
  }

  p {
    font-family: "Poppins", sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
  }
  
  a,
  a:hover,
  a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }
  
  .navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
  }
  
  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
  }
  
  /* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
  
  .wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    perspective: 1500px;
    background: #f4f7fc;
  }
  
  #sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #4A75E6;
    color: #fff;
    transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
    transform-origin: bottom left;
  }
  
  #sidebar.active {
    margin-left: -250px;
    transform: rotateY(100deg);
  }
  
  #sidebar .sidebar-header {
    padding: 20px;
    background: #4A75E6;
  }
  
  #sidebar ul.components {
    padding: 20px 0;
    /* border-bottom: 1px solid #28404d; */
  }
  
  #sidebar ul p {
    color: #fff;
    padding: 10px;
  }
  
  #sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: white;
    text-decoration: none;
  }
 
  #sidebar ul li a:hover {
    color: #4A75E6;
    background: #fff;
  }
  #sidebar ul li .back-home {
    color: black;
  }
  
  #sidebar ul li.active > a,
  a[aria-expanded="true"] {
    color: #fff;
    background: #4A75E6;
  }
  
  a[data-toggle="collapse"] {
    position: relative;
  }
  
  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  
  ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #4A75E6;
  }
  
  ul.CTAs {
    padding: 20px;
  }
  
  ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  
  a.back-home {
    background: #fff;
    color: black;
  }
  
  a.article,
  a.article:hover {
    background: #4A75E6 !important;
    color: #fff !important;
  }
  
  /* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
  #content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
  }
  
  #sidebarCollapse {
    width: 40px;
    height: 40px;
    background: #f5f5f5;
    cursor: pointer;
  }
  
  #sidebarCollapse span {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    display: block;
    background: #555;
    transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
    transition-delay: 0.2s;
  }
  
  #sidebarCollapse span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
  }
  #sidebarCollapse span:nth-of-type(2) {
    opacity: 0;
  }
  #sidebarCollapse span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
  }
  
  #sidebarCollapse.active span {
    transform: none;
    opacity: 1;
    margin: 5px auto;
  }
  
  /* ---------------------------------------------------
      MEDIAQUERIES
  ----------------------------------------------------- */
  @media (max-width: 768px) {
    #sidebar {
      margin-left: -250px;
      transform: rotateY(90deg);
    }
    #sidebar.active {
      margin-left: 0;
      transform: none;
    }
    #sidebarCollapse span:first-of-type,
    #sidebarCollapse span:nth-of-type(2),
    #sidebarCollapse span:last-of-type {
      transform: none;
      opacity: 1;
      margin: 5px auto;
    }
    #sidebarCollapse.active span {
      margin: 0 auto;
    }
    #sidebarCollapse.active span:first-of-type {
      transform: rotate(45deg) translate(2px, 2px);
    }
    #sidebarCollapse.active span:nth-of-type(2) {
      opacity: 0;
    }
    #sidebarCollapse.active span:last-of-type {
      transform: rotate(-45deg) translate(1px, -1px);
    }
  }
  
  /* Footer */
  
  .section-footer ul li {
    margin-bottom: 5px;
  }
  
  .section-footer ul a {
    color: inherit;
  }
  
  .section-footer ul a:hover {
    color: #4A75E6;
  }
  
  .section-footer .logo-footer {
    height: 40px;
  }
  
  .section-footer .title {
    font-weight: 600;
    margin-bottom: 16px;
    color: #1e2024;
  }
  
  .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .footer-copyright {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  a.footer-btn {
    color: #007bff;
    font-weight: 500;
}

  .btn-grad {background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%)}
.btn-grad {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
 }
.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
  
.search-btn{
  margin: 0;
  border-radius: 0;
}
.custom-dropdown .dropdown-toggle::after {
  display: none;
}
.buttonContainer { 
  display: flex; 
  justify-content: flex-end;
  padding-bottom: 1em;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input {
  font-family: "Poppins", sans-serif;
}
.login-p{
  color: white;
}

.containerr {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}
.login-form{
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

/* form {

} */

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4e95cc;
  border-color: #4e95cc;
}

.btnn {
  width: 150px;
  background-color: #0a4d7f;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btnn:hover {
  background-color: #4e95cc;
}
.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.small-btn {
  width: 300px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  padding: 10px;
}

.containerr:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #F7F7F7 0%, #4e95cc 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
  padding: 20px;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btnn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.containerr.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.containerr.sign-up-mode .left-panel .image,
.containerr.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.containerr.sign-up-mode .signin-signup {
  left: 25%;
}

.containerr.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.containerr.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.containerr.sign-up-mode .right-panel .image,
.containerr.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.containerr.sign-up-mode .left-panel {
  pointer-events: none;
}

.containerr.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .containerr {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .containerr.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btnn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .containerr:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .containerr.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .containerr.sign-up-mode .left-panel .image,
  .containerr.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .containerr.sign-up-mode .right-panel .image,
  .containerr.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .containerr.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .containerr {
    padding: 1.5rem;
  }

  .containerr:before {
    bottom: 72%;
    left: 50%;
  }

  .containerr.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}


.button-container {
  display: flex;
  justify-content: space-between; /* Aligns buttons to the left and right */
  padding: 1em 0; /* Adds some vertical padding */
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1em;
    margin-top: 1em;
}
.iframe-container {
  position: relative;
  width: 60%; /* Adjust width to desired size */
  height: 500px; /* Adjust height to desired size */
  margin: 2em auto; /* Centers the container and adds vertical spacing */
  background-color: #fff; /* Keeps the background color white */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ensures the iframe takes the full width of its container */
  height: 100%; /* Ensures the iframe takes the full height of its container */
  border: none; /* Keeps the border removed */
}


/* Remove any unnecessary gray background or padding/margin around the iframe */
body, html {
  margin: 0;
  padding: 0;
  background-color: #fff; /* Set the background color to white or any desired color */
}



